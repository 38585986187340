<template>
    <div class="widget-container ccy"
    :style="innerElementHeight"
    >
        <div v-if="lastCurrencyPush">
            <table>
                <thead>
                    <tr>
                        <td class="change-order"
                        :style="cellStyles"
                        >
                            <a 
                            @click="changeOrder"
                            href="javascript:void(0)">
                             <VueCustomTooltip :label="labelText" position="is-right">
                                <sort-up-icon v-if="orderBy.ranking === 'asc'" :style="'transform: scale('+ cellSvgScaling +')'"/>
                                <sort-down-icon v-else :style="'transform: scale('+ cellSvgScaling +')'" />
                                </VueCustomTooltip>
                            </a>
                        </td>
                        <td v-for="ccy in currenciesArray"
                        :key="ccy"
                        class="table-label"
                        style="position: relative;"
                        :style="cellStyles"
                        >
                            <padlock-overlay
                            :impID="details.implementation_id"
                            v-if="isLocked"
                            class="sm-padlock blur"
                            ></padlock-overlay>
                            {{ ccy }}
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in currencyCollections"
                    :key="row.label">
                        <td class="table-label"
                        style="position: relative;"
                        :style="cellStyles"
                        >
                            <padlock-overlay
                            :impID="details.implementation_id"
                            v-if="isLocked"
                            class="sm-padlock blur"
                            ></padlock-overlay>
                            {{ row.label }}
                        </td>
                        <currency-cell
                        v-for="ccy in currenciesArray"
                        :key="ccy"
                        :data="row.data[ccy]"
                        :lowestInRange="lowestInRange"
                        :styles="cellStyles"
                        >
                        </currency-cell>
                    </tr>
                </tbody>
            </table>
            <div class="last-updated-at">Last updated: 
                <span v-if="humanizedUpdatedAt">{{ humanizedUpdatedAt }}</span>
                <span v-else><font-awesome-icon :icon="['fas', 'hourglass-half']"/></span></div>
        </div>
        <div v-else class="loading-message">Getting the data ready...</div>
    </div>

</template>

<script>
import CurrencyCell from "./CurrencyCell.vue";
import SortDownIcon from '../../../../assets/SortDownIcon.vue';
import SortUpIcon from '../../../../assets/SortUpIcon.vue';
import {createNamespacedHelpers} from "vuex";
import _ from 'lodash';
import { eventBus } from '../../../../main';
import { humanizedTimeDifference, isMarketWeekend, usersTime } from '../../../../utility/datetime';
import CurrencyService from '../../../../services/currency/currency.service';
import ContentLockedMixin from '../../../../mixins/contentLockedMixin';
import PadlockOverlay from '../../../../assets/PadlockOverlay.vue';

const currency = createNamespacedHelpers('currency')
const dashboard = createNamespacedHelpers('dashboard')

export default {
    name: "CurrencyStrengthMonitorWidget",
    components: {
        CurrencyCell,
        PadlockOverlay,
        SortDownIcon,
        SortUpIcon,
    },
    props: {
        details: Object
    },
    mixins: [ContentLockedMixin],
    created() {
        eventBus.$on("widget-resize", obj => {
            if(obj.id === this.details.layoutID) {
                this.height = obj.height;

            }
            
        });
        
        if(this.details.userSettings.orderby)
            this.orderBy = this.details.userSettings.orderby;

        this.timeFilter = 'LiveDataUpdateDailyPerf';
        if(this.details.userSettings.defaultboard) {
            this.timeFilter = this.details.userSettings.defaultboard;
        }
        this.addCurrencyChannels(this.timeFilter);
            

        const obj = {
            menu: {
                isAutoScrollEnabled: false,
                isDropdownEnabled: true,
                isStreamingEnabled: true,
                options: {
                    current: this.timeFilter,
                    boards: [
                        {id: 'LiveDataUpdateDailyPerf', board_name: 'Today Since Open'},
                        // {id: 'LiveDataUpdateMonthToDate', board_name: 'Month To Date'},
                        {id: 'LiveDataUpdateRolling24', board_name: 'Rolling 24 Hours'},
                        {id: 'LiveDataUpdateRollingMonthly', board_name: 'Rolling Monthly'},
                        {id: 'LiveDataUpdateRollingQuarterly', board_name: 'Rolling Quarterly'},
                        {id: 'LiveDataUpdateRollingWeekly', board_name: 'Rolling Weekly'},
                        {id: 'LiveDataUpdateRollingYearly', board_name: 'Rolling Yearly'},
                        // {id: 'LiveDataUpdateWeekToDate', board_name: 'Week To Date'}
                    ]
                }
            }
        }

        this.interval = setInterval(() => {
            this.calculateLastUpdatedAt();
        },10000)
        this.$emit('set-frame', obj);

        this.processCurrencyData();

        if(isMarketWeekend()) {
            CurrencyService.getLastCurrenyData();
        }
        
    },
    computed: {
        ...currency.mapState(["currencyChannels", "lastPush"]),
        ...currency.mapGetters(['getLeaderBoardByKey', 'getPairsByKey', 'lastCurrencyPush']),
        ...dashboard.mapState(['fullscreenId', 'activeDashboardInfoByInstanceID']),
        activeDashboardInfo() {
            return this.activeDashboardInfoByInstanceID(this.$root.instanceID, "dashboard");
        },
        leaderboards: function () {
            this.setLastUpdate(usersTime(new Date(), true, true, true, {
                dateFormat: "y-m-d",
                timeFormat: "24hr"
            }, "UTC"));
            
            return this.getLeaderBoardByKey(this.timeFilter) || {};
        },
        pairs: function () {
            return this.getPairsByKey(this.timeFilter) || {};
        },
        currencies: function () {
            // console.log("pairs", this.pairs)
            return Object.keys(this.pairs || {});
        },
        currenciesArray() {
            // console.log(this.currencies);
            return Object.values(this.currencies);
        },
        currencyCharts: function () {
            let currencies = _.cloneDeep(this.currencies);
            currencies.splice(4, 0, 'FX Majors');
            return currencies;
        },
        leaderboardIndexes: function () {
            let map = {};
            Object.entries(this.leaderboards).forEach(([key, value]) => {
                map[key] = value[1];
            });
            // console.log("leaderboardIndexes", map);
            return map;
        },
        // chartWidth: function () {
        //     return this.containerWidth;
        // },
        // chartHeight: function () {
        //     return this.containerHeight;
        // },
        boardID: function () {
            return this.details.selectedOption;
        },
        labelText() {
            const closing = " of left axis Click the filter to change the order."
            if(this.orderBy.ranking === 'desc')
                return "Weakest top, strongest bottom" + closing;

            return "Strongest top, weakest bottom" + closing;
        },
        innerElementHeight() {
            const paddingTop = this.height > 550 ? {'padding-top': '25px'} : {};
            return {height: this.height - this.heightAdjustment + 70 + 'px', 'overflow-y': 'auto', 'overflow-x': 'auto', ...paddingTop};
        },
        fontSize() {
            if(this.height > 550) {
                if(this.details.isMdWidget)
                    return 14;
                if(this.details.isSmWidget)
                    return 13;
                if(this.details.isXsWidget)
                    return 11;
                
                return 9;
            }else{
                return 10;
            }
            
        },
        vertPadding() {
            if(this.height > 550) {
                return 14;
            }else{
                if(this.details.isLgWidget)
                    return 14;
                if(this.details.isMdWidget)
                    return 14;
                if(this.details.isSmWidget)
                    return 14;
                if(this.details.isXsWidget)
                    return 14;
                
                return 1;
            }
        },
        cellStyles() {
            let fontSize; 
            let div = 2.2;
            let vertPadding = this.vertPadding;
            if(this.height > 550) {
                if(this.details.isMdWidget)
                    fontSize = 14;
                else if(this.details.isSmWidget)
                    fontSize = 13;
                else if(this.details.isXsWidget)
                    fontSize = 11;
                else
                    fontSize = 9;
            }else{
                if(this.height > 390)
                    fontSize = 13;
                else if(this.height > 300) {
                    fontSize = 10;
                }
                else {
                    fontSize = 9;
                }
                    

                 const pairs = 8;
                
                const rowsOffset = pairs * (fontSize + 1);
                const heightBase = this.height - 220 - rowsOffset;
                vertPadding = heightBase / pairs / div;
                vertPadding < 1.5 ? vertPadding = 1.5 : vertPadding;
            }
        //    console.log(this.height, 8);

            const styles = {
                fontSize: `${fontSize}px`
            }
            if(this.details.isLgWidget)
                return {
                    ...styles,
                    padding: `${vertPadding}px 0`
                };
            if(this.details.isMdWidget)
                return {
                    ...styles,
                    padding: `${vertPadding}px 0`
                };
            if(this.details.isSmWidget)
                return {
                    ...styles,
                    padding: `${vertPadding}px 0`
                };
            if(this.details.isXsWidget)
                return {
                    ...styles,
                    padding: `${vertPadding}px 0`
                };
            
            return {
                ...styles,
                padding: `${vertPadding}px 0`
            };
        },
        cellSvgScaling() {
            if(this.height < 390)
                return 0.6;
            if(this.details.isSmWidget)
                return 1;
            if(this.details.isXsWidget)
                return 0.8;
            
            return 0.6;
        }
    },
    data: function () {
        return {
            // selectedCurrency: mobileAndTabletCheck() ? 'FX Majors': '',
            // containerWidth: 300,
            // containerHeight: 150,
            // verticalHeight: '',
            // loaded: false,
            currencyCollections: [],
            // isMobile: mobileAndTabletCheck(),
            timeFilter: '',
            orderBy: { ranking: 'asc' },
            lowestInRange: 0,
            height: 0,
            heightAdjustment: 180,
            updatedAt: '',
            humanizedUpdatedAt: '',
        }
    },
    methods: {
        ...dashboard.mapActions(['updateDashboardById']),
        ...currency.mapActions(["setCurrencyChannels", "addCurrencyChannels", "setLastUpdate"]),
        // displayCurrency: function (pointerEvent, chartEvents) {
        //     if(chartEvents[0] && (this.isMobile || this.selectedCurrency !== '')) {
        //         let currentCurrency = chartEvents[0]._model.datasetLabel;
        //         let selectedIndex = chartEvents[0]._index;
        //         this.selectedCurrency = this.currencyCollections[currentCurrency].labels[selectedIndex];
        //     }
        // },
        // displayFxMajors: function () {
        //     this.selectedCurrency = "FX Majors";
        // },
        calculateLastUpdatedAt() {
            let date = usersTime(new Date(), true, true, true, {
                    dateFormat: "y-m-d",
                    timeFormat: "24hr"
                }, false, "Europe/London")

                
            // console.log(this.lastUpdate, this.lastPush);
            if(this.lastPush)
                this.humanizedUpdatedAt = humanizedTimeDifference(this.lastPush, date);
            else {
                this.humanizedUpdatedAt = "Offline"
            }
        },
        processCurrencyData: function () {
            // console.log("old", _.cloneDeep(this.currencyCollections));
            this.lowestInRange = 0;

            const currencyCollections = [];
            this.currencies.forEach(currency => {
                let conversionCurrencies = {};

                Object.keys(this.pairs[currency]).forEach(combination => {
                    let convertedCurrency = combination.replace("/", "").replace(currency, "");
                    const value = this.pairs[currency][combination]
                    conversionCurrencies[convertedCurrency] = value;
                    
                    if(this.lowestInRange > value)
                        this.lowestInRange = value;
                });
                
                
                // console.log(currency, conversionCurrencies);

                currencyCollections.push({
                    label: currency,
                    ranking: this.leaderboardIndexes[currency],
                    data: conversionCurrencies
                })
                // this.currencyCollections[currency] = {
                //     labels: Object.keys(conversionCurrencies),
                //     datasets: [
                //         {
                //             datalabels: {
                //                 listeners: {
                //                     click: function (context) {
                //                         // Receives `click` events only for labels of the first dataset.
                //                         // The clicked label index is available in `context.dataIndex`.
                //                         console.log('label ' + context.dataIndex + ' has been clicked!');
                //                     }
                //                 }
                //             },
                //             color: 'white',
                //             label: currency,
                //             backgroundColor: '#b37b1d',
                //             data: Object.values(conversionCurrencies)
                //         }
                //     ]
                // };
            });
            // this.currencyCollections['FX Majors'] = {
            //     labels: Object.keys(this.leaderboards || {}),
            //     datasets: [
            //         {
            //             datalabels: {
            //                 listeners: {
            //                     click: function (context) {
            //                         // Receives `click` events only for labels of the first dataset.
            //                         // The clicked label index is available in `context.dataIndex`.
            //                         console.log('label ' + context.dataIndex + ' has been clicked!');
            //                     }
            //                 }
            //             },
            //             label: 'FX Majors',
            //             backgroundColor: '#b37b1d',
            //             data: Object.values(this.leaderboards).map(value => value[0])
            //         }
            //     ]
            // };
            
            this.currencyCollections = _.orderBy(currencyCollections, ccy => ccy.ranking, [this.orderBy.ranking]);
            // console.log("cccccc", this.lowestInRange);
            this.calculateLastUpdatedAt();
        },
        changeOrder() {
            this.orderBy.ranking === 'asc' ? this.orderBy.ranking = 'desc' : this.orderBy.ranking = 'asc';
            this.processCurrencyData();

            this.saveSettings({ranking: this.orderBy.ranking});
        },
        saveSettings(obj) {
            this.$emit("update-user-settings", {key : 'orderby', val: obj});
        },
    },
    watch: {
        leaderboards: function () {
            this.processCurrencyData();
        },
        pairs: function () {
            // if(val) {
            //     this.loaded = true;
            // }
            this.processCurrencyData();
        },
        timeFilter: function (val) {
            if(val)
                this.processCurrencyData();
        },
        boardID: function () {
            this.timeFilter = this.boardID;
            this.addCurrencyChannels(this.boardID);
        },
        // currencyCollections: {
        //     deep: true,
        //     handler: function (nVal, oVal) {
        //         if(!_.isEqual(nVal, oVal))
        //             this.updatedAt = usersTime(new Date(), true, true, true, {
        //                 dateFormat: "y-m-d",
        //                 timeFormat: "24hr"
        //             }, "UTC");
        //     }
            
        // }
    },
    destroyed() {
        let currencyChannels = _.cloneDeep(this.currencyChannels);
        const index = currencyChannels.indexOf(this.timeFilter);
        if (index > -1) {
            currencyChannels.splice(index, 1);
        }

        this.setCurrencyChannels(currencyChannels);
        clearInterval(this.interval);
    }
}
</script>

<style scoped lang="scss">
    .widget-container {
        @include scrollbarDark;

        & > div {
            width: 100%;
            max-width: 1200px;
            margin: 0 auto;
        }
    }

    .last-updated-at,
    table {
        width: 100%;
    }

    table {
        font-size: 11px;
        border: 1px solid $separator-line-color;
        border-collapse: collapse;
        color: $input-color;

        & td,
        & th {
            width: 11%;
            border: 1px solid $separator-line-color;
            // padding: 5px; /////
            text-align: center;
        }

        .change-order .tip {
            display: none;
        }

        & .table-label { 
            font-weight: normal;
        }

    }

    .size-sm table {
        // font-size: 13px;

        // & td,
        // & th {
            // width: 11%;
            // padding: 7px; /////
        // }

        .change-order .tip {
            display: inline-block;
            font-size: 17px;
            color: $input-color-filter;
        }
    }

    // .size-md table {
    //     font-size: 14px;

    //     & td,
    //     & th {
    //         // width: 65px;
    //         padding: 12px 15px; /////
    //     }
    // }

    // .size-lg table {
    //     font-size: 14px;

    //     & td,
    //     & th {
    //         // width: 85px;
    //         padding: 14px 22px; /////
    //     }
    // }
    .ccy .vue-custom-tooltip.is-right:after {
        width: 15vw;
    }
    .loading-message {
        text-align: center;
        font-size: 14px;
        color: $input-color-filter;
    }
    
</style>
