<template>
    <td
    :class="[background, highlightClass]"
    :style="styles"
    >
        {{ this.formattedCurrVal }}
    </td>
</template>

<script>
export default {
    props: ["data", "lowestInRange", "styles"],
    // updated(){
    //         console.log(this.lowestInRange, this.highestInRange, this.rangeStep)
    //     },
    computed: {
        
        currentVal() {
            let number = this.data;


            if(number === null) 
                return null;
            if(number === 0)
                return "0.00";
            if(number * 100 % 10 === 0)
                return number + "0";

            return number;
        },
        formattedCurrVal() {
            if(this.currentVal === undefined)
                return '';
            return this.currentVal ? this.currentVal + '%' : '-';
        },
        highestInRange() {
            return this.lowestInRange * -1;
        },
        rangeStep() {
            return Math.ceil((0 - this.lowestInRange) / 3 * 100) / 100;
        },
        isStronglyBullish() {
            return this.currentVal >= this.highestInRange - this.rangeStep;
        },
        isMediumBullish() {
            return this.currentVal <= this.highestInRange - this.rangeStep && this.currentVal > 0 + this.rangeStep;
        },
        isWeaklyBullish() {
            return this.currentVal <= 0 + this.rangeStep && this.currentVal > 0;
        },
        isWeaklyBearish() {
            return this.currentVal >= 0 - this.rangeStep && this.currentVal < 0;
        },
        isMediumBearish() {
            return this.currentVal >= this.lowestInRange + this.rangeStep && this.currentVal < 0 - this.rangeStep;
        },
        isStronglyBearish() {
            return this.currentVal <= this.lowestInRange + this.rangeStep;
        },
        
        
        background() {
            if(this.isStronglyBullish)
                return 'strongly-bullish';
            else if(this.isMediumBullish)
                return 'medium-bullish';
            else if(this.isWeaklyBullish)
                return 'weakly-bullish';
            else if(this.isWeaklyBearish)
                return 'weakly-bearish';
            else if(this.isMediumBearish)
                return 'medium-bearish';
            else if(this.isStronglyBearish)
                return 'strongly-bearish';
            
            return '';
        }
        
    },
    data() {
        return {
            highlightClass: ''
            
        }
    },
    watch: {
        data: {
            handler(nVal, oVal) {
                // console.log(nVal, oVal);
                if(nVal > oVal) {
                    this.highlightClass = 'ccy-value-up';
                    setTimeout(() => {
                        this.highlightClass = '';
                    }, 300);
                }
                else if(nVal < oVal) {
                    this.highlightClass = 'ccy-value-down';
                    setTimeout(() => {
                        this.highlightClass = '';
                    }, 300);
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .strongly-bullish {
        background-color: $strongly-bullish;
    }
    .medium-bullish {
        background-color: $medium-bullish;
    }
    .weakly-bullish {
        background-color: $weakly-bullish;
    }
    .weakly-bearish {
        background-color: $weakly-bearish;
    }
    .medium-bearish {
        background-color: $medium-bearish;
    }
    .strongly-bearish {
        background-color: $strongly-bearish;
    }

    .ccy-value-up {
        background-color: $core-lightgreen!important;
    }
    .ccy-value-down {
        background-color: $core-lightred!important;
    }
</style>